import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

import { documentArchiveList } from '@/js/api';
import { useDocumentsStore } from '@/js/stores/documents';

export default function useFetchArchives(fetchOnMount?: boolean) {
  const documentsStore = useDocumentsStore();
  const { fetchArchivedDocumentsState, archivedDocuments } =
    storeToRefs(documentsStore);

  const fetchArchivedDocuments = async () => {
    if (fetchArchivedDocumentsState.value !== 'not-started') return;
    fetchArchivedDocumentsState.value = 'fetching';
    try {
      const { data } = await documentArchiveList();
      if (data) {
        archivedDocuments.value = new Map(data.map((d) => [d.id, d]));
        fetchArchivedDocumentsState.value = 'fetched';
      }
      /* v8 ignore next 2 */
    } catch {
      // swallow error
    }
  };
  if (fetchOnMount) {
    onMounted(fetchArchivedDocuments);
  }
  return {
    fetchArchivedDocuments,
  };
}
