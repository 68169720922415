import { acceptHMRUpdate, defineStore } from 'pinia';

import {
  versionArchive,
  type VersionCreate,
  versionCreate,
  type VersionRead,
  versionShare,
  versionUnarchive,
  type VersionUpdate,
  versionUpdate,
} from '@/js/api';

export interface VersionsState {
  // Map of all known Versions
  versions: Map<VersionRead['id'], VersionRead>;
  // List of versions currently being fetched
  fetching: Set<number>;
  // List of document ids whose versions have been fetched
  fetchedDocuments: Set<number>;
}

export const useVersionsStore = defineStore('versions', {
  state: (): VersionsState => ({
    versions: new Map(),
    fetching: new Set(),
    fetchedDocuments: new Set(),
  }),

  getters: {
    versionById:
      (state) =>
      (versionId: number): VersionRead | null =>
        state.versions.get(versionId) || null,

    versionsByDoc:
      (state) =>
      (docId: number): VersionRead[] =>
        [...state.versions.values()].filter(
          (v: VersionRead) => v.document === docId,
        ),

    sharedVersionsByDoc() {
      return (docId: number): VersionRead[] =>
        this.versionsByDoc(docId).filter(
          (v: VersionRead) => v.public_id !== null,
        );
    },

    fetchingVersion:
      (state) =>
      (versionId: number): boolean =>
        state.fetching.has(versionId),

    hasFetchedVersionsForDocument:
      (state) =>
      (docId: number): boolean =>
        state.fetchedDocuments.has(docId),
  },

  actions: {
    setVersion(version: VersionRead) {
      this.versions.set(version.id, version);
    },

    removeVersion(id: number) {
      this.versions.delete(id);
    },

    setVersions(versions: VersionRead[]) {
      if (!versions.length) return;
      for (const version of versions) {
        this.setVersion(version);
      }
    },

    async createVersion(data: VersionCreate) {
      try {
        const { data: response } = await versionCreate({
          body: data,
        });
        if (response) {
          this.setVersion(response);
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async updateVersion(id: number, data: VersionUpdate) {
      try {
        const { data: response } = await versionUpdate({
          path: { id },
          body: data,
        });
        if (response) {
          this.setVersion(response);
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async archiveVersion(id: number) {
      try {
        const { data: response } = await versionArchive({ path: { id } });
        if (response) {
          this.setVersion(response);
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async unarchiveVersion(id: number) {
      try {
        const { data: response } = await versionUnarchive({ path: { id } });
        if (response) {
          this.setVersion(response);
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async shareVersion(id: number, share: boolean) {
      try {
        const { data: response } = await versionShare({
          path: { id },
          body: { share },
        });
        if (response) {
          this.setVersion(response);
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },
  },
});

/* v8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVersionsStore, import.meta.hot));
}
