import { client } from '@/js/api/client/sdk.gen';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type {
  ErrorModel,
  HttpValidationError,
} from '@/js/api/client/types.gen';
import { errorHandler } from '@/js/utils/errorHandler';

export * from '@/js/api/client/sdk.gen';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
export * from '@/js/api/client/types.gen';

export type ErrorDetails = string | ErrorModel | HttpValidationError;

export class ApiError extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly request: Request;
  public readonly error: ErrorDetails;

  constructor(request: Request, response: Response, error: ErrorDetails) {
    let message: string = 'Error';
    if (typeof error === 'string') {
      message = error;
    } else if (typeof error.detail === 'string') {
      message = error.detail;
    } else if (Array.isArray(error.detail)) {
      message = error.detail.map((err) => err.msg).join(' | ');
    } else if (typeof error.detail?.reason === 'string') {
      message = error.detail.reason;
    } else {
      message = response.statusText || response.status.toString();
    }
    super(message);

    this.name = 'ApiError';
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.request = request;
    this.error = error;
  }
}

export const initApi = () => {
  client.setConfig({
    throwOnError: true,
  });

  client.interceptors.error.use(async (error, response, request) => {
    const err = new ApiError(request, response, error as ErrorDetails);
    errorHandler(err);
    return err;
  });
};
