<template>
  <IconButton
    :icon-name="account.value"
    :text="account.display"
    type="button"
    data-btn="wide"
    @click="redirect"
  />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import type { SocialAccountObject } from '@/js/utils/constants';

export default defineComponent({
  name: 'AccountLoginButton',
  props: {
    account: {
      type: Object as PropType<SocialAccountObject>,
      required: true,
    },
    next: {
      type: String,
      default: '/',
    },
  },
  methods: {
    /* v8 ignore next 6 */
    async redirect() {
      const { data: response } = await this.account.authMethod({
        query: { next: this.next },
      });
      if (response?.authorization_url) {
        window.location.href = response.authorization_url;
      }
    },
  },
});
</script>
