import { nextTick, type Ref, ref, watch } from 'vue';

interface Args {
  isEditing: Ref<boolean>;
  title: Ref<string>;
  saveTitle: (val: string) => Promise<unknown> | void;
}

export default function useTitleInput({ isEditing, title, saveTitle }: Args) {
  const input = ref<HTMLInputElement | null>(null);

  const editTitle = async (e: Event) => {
    const target = e.target as HTMLInputElement | null;
    const val = /* v8 ignore next */ target?.value || '';
    if (val && val !== title.value) await saveTitle(val);
    /* v8 ignore next */
    target?.blur();
  };

  const editTitleCancel = () => {
    if (input.value) {
      input.value.value = title.value;
      input.value.blur();
    }
  };

  watch(
    isEditing,
    async () => {
      if (isEditing.value) {
        await nextTick();
        input.value?.focus();
      }
    },
    { immediate: true },
  );

  return {
    editTitle,
    editTitleCancel,
    input,
  };
}
