const removePrintIFrame = function () {
  const iframe = document.getElementById('printIFrame');
  if (iframe) document.body.removeChild(iframe);
};

export default function (content: string, title: string) {
  // Use existing if it wasn't cleaned up for some reason
  const printIFrame =
    (document.getElementById('printIFrame') as HTMLIFrameElement) ||
    document.createElement('iframe');
  printIFrame.id = 'printIFrame';
  printIFrame.src = '/print';
  printIFrame.title = 'Print View';
  printIFrame.style.display = 'none';

  printIFrame.onload = function () {
    const iframeDoc = this as HTMLIFrameElement;
    /* v8 ignore next */
    if (!iframeDoc.contentDocument?.body || !iframeDoc.contentWindow) return;
    iframeDoc.contentDocument.title = title;
    const output = iframeDoc.contentDocument.body?.querySelector('#output');
    if (output) output.innerHTML = content;

    // Set up remove handlers, print
    iframeDoc.contentWindow.onbeforeunload = removePrintIFrame;
    iframeDoc.contentWindow.onafterprint = removePrintIFrame;
    iframeDoc.contentWindow.print();
  };
  document.body.appendChild(printIFrame);
}
