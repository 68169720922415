import type { Dictionary } from 'lodash';

import { oauthGoogleCookieAuthorize } from '@/js/api';

export type SocialAccount = 'google';
export type SocialAccountObject = {
  value: SocialAccount;
  display: string;
  authMethod: typeof oauthGoogleCookieAuthorize;
};
export const SOCIAL_ACCOUNTS: Dictionary<SocialAccountObject> = {
  GOOGLE: {
    value: 'google',
    display: 'Google',
    authMethod: oauthGoogleCookieAuthorize,
  },
};

export const SCROLL_LEFT = 'left';

export type AlertType = 'success' | 'error' | 'warning';
export const ALERT_TYPES: Dictionary<AlertType> = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export const WEBSOCKET_TYPES = {
  SUBSCRIBE: 'subscribe' as const,
  UNSUBSCRIBE: 'unsubscribe' as const,
  BROADCAST: 'broadcast' as const,
  PING: 'PING' as const,
  PONG: 'PONG' as const,
};

export type WebsocketSubscribeAction = 'list' | 'retrieve';
export const WEBSOCKET_SUBSCRIBE_ACTIONS = {
  LIST: 'list' as const,
  RETRIEVE: 'retrieve' as const,
};

export type WebsocketAction = 'UPDATED' | 'CREATED' | 'DELETED';
export const WEBSOCKET_ACTIONS: Dictionary<WebsocketAction> = {
  UPDATED: 'UPDATED',
  CREATED: 'CREATED',
  DELETED: 'DELETED',
};

export type ObjectType = 'Document' | 'Version';
export const OBJECT_TYPES: Dictionary<ObjectType> = {
  DOCUMENT: 'Document',
  VERSION: 'Version',
};

export const ALLOWED_FORMATS = [
  'bold',
  'code',
  'code-block',
  'header',
  'italic',
  'link',
  'list',
  'underline',
];

export type SaveState = 'inactive' | 'saving' | 'saved';
export const SAVE_STATE: Dictionary<SaveState> = {
  INACTIVE: 'inactive' as const,
  SAVING: 'saving' as const,
  SAVED: 'saved' as const,
};
