import { isNaN, toNumber } from 'lodash';
import { computed, onMounted, watch } from 'vue';

import { versionRead } from '@/js/api';
import { useVersionsStore } from '@/js/stores/versions';

interface Props {
  id: string;
}

export default function useFetchVersion(props: Readonly<Props>) {
  const versionsStore = useVersionsStore();
  const versionId = computed(() => {
    const id = toNumber(props.id);
    return isNaN(id) ? null : id;
  });
  const fetching = computed(() =>
    versionId.value ? versionsStore.fetchingVersion(versionId.value) : false,
  );
  const version = computed(() =>
    versionId.value ? versionsStore.versionById(versionId.value) : null,
  );

  const fetchVersion = async () => {
    if (versionId.value !== null && !version.value && !fetching.value) {
      const id = versionId.value;
      versionsStore.fetching.add(id);
      try {
        const { data: response } = await versionRead({ path: { id } });
        if (response) {
          versionsStore.setVersion(response);
        }
        /* v8 ignore next 2 */
      } catch {
        // swallow error
      } finally {
        versionsStore.fetching.delete(id);
      }
    }
  };

  onMounted(fetchVersion);
  watch(versionId, fetchVersion);

  return {
    version,
    fetching,
  };
}
