import { ApiError } from '@/js/api';
import getRouter from '@/js/router';
import { useAlertsStore } from '@/js/stores/alerts';
import { ALERT_TYPES } from '@/js/utils/constants';
import { logError } from '@/js/utils/logging';

export function errorHandler(error: ApiError) {
  logError(
    error,
    typeof error.error === 'string'
      ? { error: error.error }
      : /* v8 ignore next */ error.error || {},
  );

  const router = getRouter();
  const addAlert = useAlertsStore().addAlert;

  if (error instanceof ApiError) {
    // Skip generic 404 alert
    if (error.status === 404) return;

    if (typeof error.error === 'string' || !error.error.detail) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message,
      });
      return;
    }

    if (typeof error.error.detail === 'string') {
      switch (error.error.detail) {
        case 'REGISTER_USER_ALREADY_EXISTS':
          addAlert({
            type: ALERT_TYPES.WARNING,
            message: 'This account already exists. Please sign in.',
          });
          router.push({ name: 'Login' });
          return;
        case 'VERIFY_USER_ALREADY_VERIFIED':
          // This is handled directly in `confirm-email-form.vue`
          return;
        case 'LOGIN_USER_NOT_VERIFIED':
          addAlert({
            type: ALERT_TYPES.WARNING,
            message:
              'Please confirm your account by clicking the confirmation link in your email.',
          });
          return;
        case 'LOGIN_BAD_CREDENTIALS':
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: 'You have entered an invalid email or password.',
          });
          return;
        default:
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: error.message,
          });
          return;
      }
    }

    if (Array.isArray(error.error.detail)) {
      for (const details of error.error.detail) {
        if (details.msg) {
          addAlert({
            type: ALERT_TYPES.ERROR,
            message: details.msg,
          });
        }
      }
      return;
    }

    if (
      error.error.detail?.code === 'REGISTER_INVALID_PASSWORD' &&
      error.error.detail?.reason
    ) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.error.detail.reason,
      });
      return;
    }
  }
}
