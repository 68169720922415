import type { Dictionary } from 'lodash';

export const logError = (
  message: string | Error,
  data: Dictionary<unknown> = {},
) => {
  if (window.Sentry) {
    if (message instanceof Error) {
      window.Sentry.captureException(message, { extra: data });
    } else {
      window.Sentry.captureMessage(message, { extra: data });
    }
  }
  window.console.error(message, data);
};

export const log = (...args: unknown[]) => {
  window.console.info(...args);
};
