import { acceptHMRUpdate, defineStore } from 'pinia';

import {
  authCookieLogout,
  type UserRead,
  usersPatchCurrentUser,
} from '@/js/api';
import getRouter from '@/js/router';
import { useAlertsStore } from '@/js/stores/alerts';
import { useDocumentsStore } from '@/js/stores/documents';
import { useRootStore } from '@/js/stores/root';
import { useSocketStore } from '@/js/stores/socket';
import { useVersionsStore } from '@/js/stores/versions';

export interface UserState {
  user: UserRead | null;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({ user: null }),

  actions: {
    hydrate() {
      const user: UserRead | null = window.CONFIG?.USER || null;
      this.user = user;
    },

    loggedIn() {
      if (!this.user) {
        return null;
      }
      useDocumentsStore().hydrate();
      useSocketStore().hydrate();
      if (window.Sentry) {
        window.Sentry.setUser(this.user);
      }
      return this.user;
    },

    loggedOut() {
      this.$reset();
      useAlertsStore().$reset();
      useDocumentsStore().$reset();
      useVersionsStore().$reset();
      useRootStore().$reset();
      // Socket store has custom logic for closing websocket connection
      useSocketStore().resetStore();

      if (window.Sentry) {
        window.Sentry.setUser(null);
      }
      return getRouter().push({
        name: 'Login',
        query: {
          next:
            window.location.pathname === '/write'
              ? undefined
              : window.location.pathname,
        },
      });
    },

    async logOut() {
      try {
        await authCookieLogout();
      } catch (error) {
        // swallow error
      }
      return this.loggedOut();
    },

    async update(changes: Partial<UserRead>) {
      try {
        const { data: user } = await usersPatchCurrentUser({
          body: changes,
        });
        this.user = user ?? null;
        return this.user;
      } catch {
        return false;
      }
    },
  },
});

/* v8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
