<template>
  <PanelHeader :panel-index="panelIndex">
    <template #menu>
      <PanelHeaderMenu
        :panel-index="panelIndex"
        :document-id="document.id"
        is-list
      >
        <hr />
        <UtilityMenuButton
          :disabled="!connected"
          @click="setEditingDocument(document.id)"
        >
          Rename
        </UtilityMenuButton>
        <UtilityMenuButton :disabled="!connected" @click="saveVersion">
          Save a Version
        </UtilityMenuButton>
        <UtilityMenuButton :disabled="!connected" @click="saveAsDocument">
          Save as New Document
        </UtilityMenuButton>
        <UtilityMenuButton
          :disabled="!connected"
          @click="shareModalOpen = true"
        >
          Share
        </UtilityMenuButton>
        <hr />
        <UtilityMenuButton
          :disabled="!connected"
          @click="archiveDocModalOpen = true"
        >
          Archive
        </UtilityMenuButton>
      </PanelHeaderMenu>
    </template>

    <DocumentTitle :title="document.title" />
  </PanelHeader>

  <div class="content-wrapper">
    <div data-panel="scroll-content flex">
      <header class="list-header">
        <h2 class="list-title">My Versions</h2>
      </header>
      <VersionList
        :panel-index="panelIndex"
        :document="document"
        start-expanded
        :editing-version-id="editingVersionId"
        @editing-version-id="(val) => (editingVersionId = val)"
      />
    </div>
  </div>

  <DocumentArchiveModal
    v-if="archiveDocModalOpen"
    :document-id="document.id"
    :document-title="document.title"
    :document-is-shared="!!document.public_id"
    :connected="connected"
    @close="archiveDocModalOpen = false"
  />

  <DocumentShareModal
    v-if="shareModalOpen"
    :version-id="document.id"
    is-current
    :public-id="document.public_id"
    :connected="connected"
    @close="shareModalOpen = false"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  type PropType,
  ref,
  toRefs,
} from 'vue';

import type { DocumentRead } from '@/js/api';
import DocumentArchiveModal from '@/js/components/document/archive-modal.vue';
import DocumentShareModal from '@/js/components/document/share-modal.vue';
import DocumentTitle from '@/js/components/document/title.vue';
import PanelHeader from '@/js/components/panels/header.vue';
import PanelHeaderMenu from '@/js/components/panels/header-menu.vue';
import VersionList from '@/js/components/version/list.vue';
import { navigatePanel } from '@/js/router/panels';
import { useDocumentsStore } from '@/js/stores/documents';
import { useSocketStore } from '@/js/stores/socket';
import { useVersionsStore } from '@/js/stores/versions';

export default defineComponent({
  name: 'PanelVersionList',
  components: {
    DocumentArchiveModal,
    DocumentShareModal,
    DocumentTitle,
    PanelHeader,
    PanelHeaderMenu,
    VersionList,
  },
  props: {
    panelIndex: {
      type: Number,
      required: true,
    },
    document: {
      type: Object as PropType<DocumentRead>,
      required: true,
    },
  },
  setup(props) {
    const { document, panelIndex } = toRefs(props);
    const socketStore = useSocketStore();
    const documentsStore = useDocumentsStore();
    const versionStore = useVersionsStore();

    const connected = computed(() => socketStore.connected);
    const editingVersionId = ref<number | null>(null);
    const archiveDocModalOpen = ref(false);
    const shareModalOpen = ref(false);

    const saveVersion = async () => {
      const version = await versionStore.createVersion({
        document: document.value.id,
        title: 'Untitled',
      });
      if (version) {
        editingVersionId.value = version.id;
      }
    };

    const saveAsDocument = async () => {
      const doc = await documentsStore.createDocument({
        parent_id: document.value.id,
        title: `Copy of ${document.value.title}`,
        text: document.value.text,
      });
      /* v8 ignore next */
      if (!doc) return;
      navigatePanel({
        to: `document-${doc.id}`,
        panelIndex: panelIndex.value,
      });
    };

    onMounted(() => {
      if (history.state?.editingVersionId) {
        const versionId: number = history.state.editingVersionId;
        history.replaceState({ ...history.state, editingVersionId: null }, '');
        editingVersionId.value = versionId;
      }
    });

    return {
      archiveDocModalOpen,
      connected,
      editingVersionId,
      saveAsDocument,
      saveVersion,
      setEditingDocument: documentsStore.setEditingDocument,
      shareModalOpen,
    };
  },
});
</script>
