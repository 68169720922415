<template>
  <div v-if="state === STATES.VALIDATING">
    <BooksIcon name="spinner" status="saving muted" />
  </div>
  <div
    v-else-if="state === STATES.INVALID_TOKEN"
    data-testid="invalid"
    data-layout="account"
  >
    <div data-alert="error" data-result="with-form">
      <BooksIcon name="warning" size="medium" />
      <span class="alert"
        >Invalid token provided. Please click the confirmation link in your
        email to try again.</span
      >
    </div>
    <form ref="form" data-testid="form" @submit.prevent="resendVerification">
      <AccountFieldEmail v-if="!tokenEmail" v-model="email" />
      <div class="form-actions">
        <button data-btn="wide" type="submit" :disabled="!valid">
          Resend email
        </button>
      </div>
    </form>
  </div>
  <div v-else-if="state === STATES.ERROR" data-layout="account">
    <div data-alert="error" data-result="with-form" data-testid="error">
      <BooksIcon name="warning" size="medium" />
      <span class="alert">{{ errorMessage }}</span>
    </div>
    <button
      v-if="email"
      data-btn="wide"
      type="button"
      @click="resendVerification"
    >
      Resend verification email
    </button>
  </div>
  <div
    v-else-if="state === STATES.RESENT"
    data-alert="success"
    data-result
    data-testid="resent"
    data-layout="account"
  >
    <BooksIcon name="checkmark" size="medium" />
    <span class="alert"
      >Confirmation link re-sent. Check your email inbox, and follow the link to
      continue.</span
    >
  </div>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';

import { ApiError, verifyRequestToken, verifyVerify } from '@/js/api';
import AccountFieldEmail from '@/js/components/account/field-email.vue';
import { useAlertsStore } from '@/js/stores/alerts';
import { ALERT_TYPES } from '@/js/utils/constants';
import * as token from '@/js/utils/token';

const STATES = {
  VALIDATING: 'validating',
  INVALID_TOKEN: 'invalid-token',
  ERROR: 'error',
  RESENT: 'resent',
};

export default defineComponent({
  name: 'AccountConfirmEmailForm',
  components: { AccountFieldEmail },
  props: {
    token: { type: String, default: undefined },
  },
  data() {
    return {
      state: STATES.VALIDATING,
      errorMessage: '',
      email: '',
      tokenEmail: '',
      STATES: Object.freeze(STATES),
      valid: false,
    };
  },
  watch: {
    email() {
      const form = this.$refs.form as HTMLFormElement;
      if (form) this.valid = form.checkValidity();
    },
  },
  created() {
    this.submitToken();
  },
  methods: {
    ...mapActions(useAlertsStore, ['addAlert']),
    confirmSucceeded() {
      this.addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Thank you for confirming your email address!',
      });
      this.$router.push({ name: 'Login' });
    },
    async submitToken() {
      const tokenContents = token.decodeToken(
        this.token,
        'fastapi-users:verify',
      );
      if (!tokenContents.isValid) {
        this.state = STATES.INVALID_TOKEN;
        this.tokenEmail = this.email = tokenContents.email || '';
      } else {
        try {
          await verifyVerify({
            body: {
              token: this.token!,
            },
          });
          this.confirmSucceeded();
        } catch (error) {
          if (
            error instanceof ApiError &&
            typeof error.error !== 'string' &&
            error.error.detail === 'VERIFY_USER_ALREADY_VERIFIED'
          ) {
            this.confirmSucceeded();
            return;
          }
          this.state = STATES.ERROR;
          this.errorMessage = "Uh oh! We weren't able to confirm your email.";
        }
      }
    },
    async resendVerification() {
      try {
        await verifyRequestToken({
          body: {
            email: this.email,
          },
        });
        this.state = STATES.RESENT;
      } catch (error) {
        this.state = STATES.ERROR;
        this.errorMessage =
          "Uh oh! We weren't able to resend your confirmation email.";
      }
    },
  },
});
</script>
