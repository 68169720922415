<template>
  <template v-if="!success">
    <div data-layout="account">
      <h2 data-account-title>Register</h2>
      <form ref="form" data-testid="form" @submit.prevent="submit">
        <AccountFieldName v-model="name" :disabled="submitting" />
        <AccountFieldEmail v-model="email" :disabled="submitting" />
        <AccountFieldPassword
          v-model="password"
          :disabled="submitting"
          new-password
        />
        <div class="form-actions">
          <button
            type="submit"
            :disabled="submitting || !valid"
            data-btn="wide"
          >
            Register
          </button>
        </div>
      </form>
      <ul data-secondary-actions data-list="plain">
        <li>
          Already have an account?
          <RouterLink :to="{ name: 'Login' }">Sign in »</RouterLink>
        </li>
      </ul>
    </div>
  </template>
  <div
    v-else
    data-testid="success"
    data-alert="success"
    data-result
    data-layout="account"
  >
    <BooksIcon name="checkmark" size="medium" />
    <span class="alert">
      Welcome to OddBooks, <strong>{{ name }}</strong
      >! Check your email inbox for a confirmation link to continue.
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { registerRegister } from '@/js/api';
import AccountFieldEmail from '@/js/components/account/field-email.vue';
import AccountFieldName from '@/js/components/account/field-name.vue';
import AccountFieldPassword from '@/js/components/account/field-password.vue';

export default defineComponent({
  name: 'AccountRegisterUserForm',
  components: { AccountFieldEmail, AccountFieldName, AccountFieldPassword },
  props: {
    next: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      email: '',
      password: '',
      name: '',
      submitting: false,
      valid: false,
      success: false,
    };
  },
  watch: {
    email() {
      this.checkValidity();
    },
    password() {
      this.checkValidity();
    },
    name() {
      this.checkValidity();
    },
  },
  methods: {
    async submit(event: Event) {
      const form = event.target as HTMLFormElement;
      if (!form.checkValidity()) return;
      this.submitting = true;
      try {
        await registerRegister({
          body: {
            email: this.email,
            password: this.password,
            name: this.name,
            // generate a random number between 0-6 for initial color
            avatar_color: Math.floor(Math.random() * 7),
          },
        });
        this.success = true;
        return true;
      } catch (err) {
        return false;
      } finally {
        this.submitting = false;
      }
    },
    checkValidity() {
      const form = this.$refs.form as HTMLFormElement;
      this.valid = form.checkValidity();
    },
  },
});
</script>
