<template>
  <div data-layout="account">
    <form ref="form" data-testid="form" @submit.prevent="submit">
      <AccountFieldPassword
        v-model="password"
        :disabled="submitting"
        new-password
      />
      <div>
        <button type="submit" :disabled="submitting || !valid" data-btn="wide">
          Reset Password
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';

import { resetResetPassword } from '@/js/api';
import AccountFieldPassword from '@/js/components/account/field-password.vue';
import { useAlertsStore } from '@/js/stores/alerts';
import { ALERT_TYPES } from '@/js/utils/constants';
import * as token from '@/js/utils/token';

export default defineComponent({
  name: 'AccountSetNewPasswordForm',
  components: { AccountFieldPassword },
  props: {
    token: { type: String, default: undefined },
  },
  data() {
    return {
      password: '',
      submitting: false,
      valid: false,
    };
  },
  watch: {
    password() {
      const form = this.$refs.form as HTMLFormElement;
      this.valid = form.checkValidity();
    },
  },
  created() {
    const tokenContents = token.decodeToken(this.token, 'fastapi-users:reset');
    if (!tokenContents.isValid) {
      this.addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Invalid link. Please try resetting your password again.',
      });
      this.$router.push({ name: 'ResetPassword' });
    }
  },
  methods: {
    ...mapActions(useAlertsStore, ['addAlert']),
    async submit() {
      this.submitting = true;
      try {
        await resetResetPassword({
          body: {
            password: this.password,
            token: this.token!,
          },
        });
        this.addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: 'Password reset successfully! Please sign in.',
        });
        this.$router.push({ name: 'Login' });
      } catch (err: unknown) {
        this.password = '';
        return false;
      } finally {
        this.submitting = false;
      }
    },
  },
});
</script>
