import { onMounted, type Ref, ref, watch } from 'vue';

import {
  type DocumentReadPublic,
  documentReadPublic,
  type VersionReadPublic,
  versionReadPublic,
} from '@/js/api';

type Props = {
  publicId: Ref<string>;
  type: Ref<string>;
};

export default function useFetchPublicView(props: Props) {
  const fetching = ref(false);
  const document = ref<DocumentReadPublic | null>(null);
  const version = ref<VersionReadPublic | null>(null);

  const fetchPublicVersion = async () => {
    if (
      props.publicId.value &&
      ['document', 'version'].includes(props.type.value)
    ) {
      fetching.value = true;
      try {
        switch (props.type.value) {
          case 'document': {
            const { data: response } = await documentReadPublic({
              path: { public_id: props.publicId.value },
            });
            if (response) {
              document.value = response;
            }
            break;
          }
          case 'version': {
            const { data: response } = await versionReadPublic({
              path: { public_id: props.publicId.value },
            });
            if (response) {
              version.value = response;
            }
            break;
          }
        }
        /* v8 ignore next 2 */
      } catch {
        // swallow error
      } finally {
        fetching.value = false;
      }
    }
  };

  onMounted(fetchPublicVersion);
  watch([props.publicId, props.type], fetchPublicVersion);

  return {
    document,
    version,
    fetching,
  };
}
