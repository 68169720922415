<template>
  <template v-if="!success">
    <div data-layout="account">
      <h2 data-account-title>Reset Password</h2>
      <form ref="form" data-testid="form" @submit.prevent="submit">
        <AccountFieldEmail v-model="email" :disabled="submitting" />
        <div class="form-actions">
          <button
            type="submit"
            :disabled="submitting || !valid"
            data-btn="wide"
          >
            Send Reset Link
          </button>
        </div>
      </form>
      <ul data-secondary-actions data-list="plain">
        <li>
          Need an account?
          <RouterLink :to="{ name: 'RegisterUser' }">
            Register now »
          </RouterLink>
        </li>
        <li>
          Found your password?
          <RouterLink :to="{ name: 'Login' }">Sign in »</RouterLink>
        </li>
      </ul>
    </div>
  </template>
  <div
    v-else
    data-testid="success"
    data-layout="account"
    data-alert="success"
    data-result
  >
    <BooksIcon name="checkmark" size="medium" />
    <span class="alert"
      >Success! Check your email for a link to change your password.</span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { resetForgotPassword } from '@/js/api';
import AccountFieldEmail from '@/js/components/account/field-email.vue';

export default defineComponent({
  name: 'AccountResetPasswordForm',
  components: { AccountFieldEmail },
  data() {
    return {
      email: '',
      submitting: false,
      success: false,
      valid: false,
    };
  },
  watch: {
    email() {
      const form = this.$refs.form as HTMLFormElement;
      this.valid = form.checkValidity();
    },
  },
  methods: {
    async submit() {
      this.submitting = true;
      try {
        await resetForgotPassword({
          body: {
            email: this.email,
          },
        });
        this.success = true;
      } catch (err: unknown) {
        return false;
      } finally {
        this.submitting = false;
      }
    },
  },
});
</script>
