import { includes, intersection, map, orderBy } from 'lodash';
import { acceptHMRUpdate, defineStore } from 'pinia';

import {
  documentArchive,
  type DocumentCreate,
  documentCreate,
  type DocumentRead,
  documentShare,
  documentUnarchive,
  type DocumentUpdate,
  documentUpdate,
} from '@/js/api';
import { navigatePanel } from '@/js/router/panels';
import { useAlertsStore } from '@/js/stores/alerts';
import { useRootStore } from '@/js/stores/root';
import { useVersionsStore } from '@/js/stores/versions';
import { ALERT_TYPES } from '@/js/utils/constants';
import { escapeHTML } from '@/js/utils/escape';

export interface DocumentsState {
  // Map of all Documents
  documents: Map<DocumentRead['id'], DocumentRead>;
  // Map of strings of unsaved Document texts
  drafts: Map<DocumentRead['id'], string>;
  // Which document is currently in title-edit mode
  editing: DocumentRead['id'] | null;
  // List of documents whose versions are currently being fetched
  fetching: Set<DocumentRead['id']>;
  // Map of all Archived Documents
  archivedDocuments: Map<DocumentRead['id'], DocumentRead>;
  // Single-use state of archived docs fetch
  fetchArchivedDocumentsState: 'not-started' | 'fetching' | 'fetched';
}

export const useDocumentsStore = defineStore('documents', {
  state: (): DocumentsState => ({
    documents: new Map(),
    drafts: new Map(),
    editing: null,
    fetching: new Set(),
    archivedDocuments: new Map(),
    fetchArchivedDocumentsState: 'not-started',
  }),

  getters: {
    documentById:
      (state) =>
      (id: number): DocumentRead | null =>
        state.documents.get(id) || null,

    documentOrArchiveById:
      (state) =>
      (id: number): DocumentRead | null =>
        state.documents.get(id) || state.archivedDocuments.get(id) || null,

    draftByDocument:
      (state) =>
      (id: number): string | undefined =>
        state.drafts.get(id),

    fetchingVersions:
      (state) =>
      (id: number): boolean =>
        state.fetching.has(id),

    documentIsOpen() {
      const versionsStore = useVersionsStore();
      return function (id: number): boolean {
        const rootStore = useRootStore();
        if (includes(rootStore.panels.documents, id)) {
          return true;
        }

        const versionIds = map(versionsStore.versionsByDoc(id), 'id');
        if (intersection(rootStore.panels.versions, versionIds).length > 0) {
          return true;
        }

        return false;
      };
    },

    orderedDocuments(state) {
      return orderBy([...state.documents.values()], 'text_modified_at', [
        'desc',
      ]);
    },

    orderedArchivedDocuments(state) {
      return orderBy(
        [...state.archivedDocuments.values()],
        ['deleted', 'text_modified_at'],
        ['desc'],
      );
    },
  },

  actions: {
    hydrate() {
      const documents: DocumentRead[] = window.CONFIG?.DOCUMENTS || [];
      this.documents = new Map(documents.map((d) => [d.id, d]));
    },

    setDocument(document: DocumentRead) {
      this.documents.set(document.id, document);
    },

    removeDocument(id: number) {
      this.documents.delete(id);
    },

    setDraft({ text, id }: { text: string; id: number }) {
      this.drafts.set(id, text);
    },

    removeDraft(id: number) {
      this.drafts.delete(id);
    },

    setEditingDocument(id: number | null) {
      useRootStore().navOpen = true;
      this.editing = id;
    },

    async createDocument(data: DocumentCreate) {
      try {
        const { data: response } = await documentCreate({
          body: data,
        });
        if (response) {
          this.setDocument(response);
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async updateDocument(id: number, data: DocumentUpdate) {
      try {
        const { data: response } = await documentUpdate({
          path: { id },
          body: data,
        });
        if (response) {
          this.setDocument(response);
          if (response.text === this.draftByDocument(id)) {
            this.removeDraft(id);
          }
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async shareDocument(id: number, share: boolean) {
      try {
        const { data: response } = await documentShare({
          path: { id },
          body: { share },
        });
        if (response) {
          this.setDocument(response);
        }
        return response;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async archiveDocument(id: number, title: string) {
      const alertsStore = useAlertsStore();
      const versionsStore = useVersionsStore();
      try {
        const { data: doc } = await documentArchive({ path: { id } });
        if (!doc) return false;
        this.archivedDocuments.set(doc.id, doc);
        this.removeDocument(id);

        // Remove versions from local store
        const versions = versionsStore.versionsByDoc(doc.id);
        versions.forEach((version) => {
          versionsStore.removeVersion(version.id);
        });
        versionsStore.fetchedDocuments.delete(doc.id);

        alertsStore.addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: `You have archived the document <strong>${escapeHTML(
            title,
          )}</strong>.`,
          safe: true,
          dismiss: true,
          actionLabel: 'Go to Archives',
          actionLink: 'archive',
        });
        return true;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async restoreDocument(id: number) {
      const alertsStore = useAlertsStore();
      try {
        const { data: doc } = await documentUnarchive({ path: { id } });
        if (!doc) return false;
        this.setDocument(doc);
        this.archivedDocuments.delete(id);
        alertsStore.addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: `You have restored the document <strong>${escapeHTML(
            doc.title,
          )}</strong>.`,
          safe: true,
          dismiss: true,
          actionLabel: 'Go to Archives',
          actionLink: 'archive',
        });
        return true;
        /* v8 ignore next 3 */
      } catch {
        return false;
      }
    },

    async restoreFromVersion(id: number, data: DocumentUpdate) {
      const alertsStore = useAlertsStore();
      const versionsStore = useVersionsStore();
      // First auto-save a version from the current text
      const version = await versionsStore.createVersion({
        document: id,
        title: 'Autosaved Before Restoring From Version',
      });
      if (!version) return false;
      // Then replace current text with chosen version text
      const doc = await this.updateDocument(id, data);
      if (!doc) return false;
      this.removeDraft(id);
      alertsStore.addAlert({
        type: ALERT_TYPES.SUCCESS,
        message:
          'The document was autosaved before successfully restoring the selected version.',
      });
      return doc;
    },

    async addDocument() {
      const doc = await this.createDocument({
        title: 'New Document',
        text: '<p><br></p>',
      });
      if (!doc) return false;
      navigatePanel({
        to: `document-${doc.id}`,
      });
    },
  },
});

/* v8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDocumentsStore, import.meta.hot));
}
