import { acceptHMRUpdate, defineStore } from 'pinia';

export interface Panels {
  documents: number[];
  versions: number[];
  accounts: number[];
  archives: number[];
}

export interface RootState {
  navOpen: boolean;
  panels: Panels;
}

export const useRootStore = defineStore('root', {
  state: (): RootState => ({
    navOpen: false,
    panels: { documents: [], versions: [], accounts: [], archives: [] },
  }),

  actions: {
    toggleNav() {
      this.navOpen = !this.navOpen;
    },

    closeAllPanels() {
      this.panels = { documents: [], versions: [], accounts: [], archives: [] };
    },
  },
});

/* v8 ignore next 3 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot));
}
